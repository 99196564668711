import { graphql } from 'gatsby'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import React from 'react'
import styled from 'styled-components'
import { layout } from '../components/GlobalStyles/vars/layout'
import { HeroImage } from '../components/HeroImage'
import { Layout } from '../components/Layout/Layout'
import { Listing } from '../components/Listing'
import { SEO } from '../helpers/SEO'
import { imageUrlFor, buildImageObj } from '../helpers/imageUrl.js'
import { device } from '../components/GlobalStyles/helpers/device'

const PageContainer = styled.div``

const FleetContainer = styled.ul`
  ${layout.pageWidth}
  margin: 50px auto;

  @media ${device.tablet} {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 35px;

    li {
      margin-top: 0;
    }
  }
`

interface Aircraft {
  id: string
  model: string
  make: string
  slug: {
    current: string
  }
  tailNumber: string
  year: string
  image: {
    alt: string
  }
}

interface Props {
  data: {
    fleet: { nodes: [Aircraft] }
    image: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
  }
}

const Fleet = ({ data }: Props): JSX.Element => {
  const image = data.image.childImageSharp.fluid
  const fleet = data.fleet.nodes

  return (
    <>
      <SEO
        title="Our Fleet"
        description="See the aircraft we operate, and you learn in. Tech details, rates, and pictures."
      />
      <Layout>
        <PageContainer>
          <HeroImage alt="Aircraft on airport ramp at sunset." fluid={image} />
          <h1>Our Fleet</h1>
          <p>
            Queen City Flying Service maintains a fleet of well cared for aircraft that, unlike many other flight school
            fleets, are stored inside heated hangars year round. Our fleet includes the Cessna 172 Skyhawk, the
            world&apos;s most popular training aircraft.
          </p>
          <p>
            Our hosts at Waypoint Aviation have years of aircraft management experience and a first-class hangar for
            storage. Maintenance services are provided by onsite by Airtech, Waypoint’s sister company.
          </p>
          <FleetContainer>
            {fleet.map((aircraft) => {
              return (
                <Listing
                  key={aircraft.id}
                  link={aircraft.slug.current}
                  title={aircraft.tailNumber}
                  subtitle={`${aircraft.year} ${aircraft.make} ${aircraft.model}`}
                  img={imageUrlFor(buildImageObj(aircraft.image)).width(100).height(100).auto('format').url() || ''}
                  alt={aircraft.image.alt}
                />
              )
            })}
          </FleetContainer>
        </PageContainer>
      </Layout>
    </>
  )
}

export default Fleet

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  {
    fleet: allSanityAircraft {
      nodes {
        id
        model
        make
        slug {
          current
        }
        tailNumber
        year
        image {
          alt
          ...SanityImage
        }
      }
    }
    image: file(relativePath: { eq: "fleet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
